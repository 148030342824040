@use '@angular/material' as mat;
@use "sass:map";
@import 'src/styles/_tools/mixins';

// One usage of this is when using headlines font to create some content, headlines have a margin by default
:root {
  --gutter: 16px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-line-height {
  line-height: 1 !important;
}

.underline {
  text-decoration: underline !important;
}

.no-underline {
  text-decoration: none !important;
}

.relative {
  position: relative;
}

.display-contents {
  display: contents;
}

.scroll-x {
  overflow-x: scroll;
}

.clickable-element {
  @include mat.elevation-transition();
  @include mat.elevation(2);

  &:hover {
    @include mat.elevation(8);
  }
}

.clickable {
  cursor: pointer;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.separator {
  width: 100%;
  margin: 24px 0;
  border: 1px solid color('primary', 200);
}

.vertical-separator {
  min-height: 24px;
  height: 100%;
  border-left: 2px solid color('primary', 200);
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.padding-top-18 {
  padding-top: 18px;
}

.padding-right-12 {
  padding-right: 12px;
}

.padding-left-16 {
  padding-left: 16px;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-right-16 {
  padding-right: 16px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-left-24 {
  padding-left: 24px;
}

.padding-top-24 {
  padding-top: 24px;
}

.padding-right-24 {
  padding-right: 24px;
}

.padding-bottom-24 {
  padding-bottom: 24px !important;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-bottom-4 {
  margin-bottom: 4px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-36 {
  margin-bottom: 36px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-16 {
  margin-right: 16px;
}

.margin-right-24 {
  margin-right: 24px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-left-24 {
  margin-left: 24px;
}

.margin-left-40 {
  margin-left: 40px;
}

.overlay-panel-class-no-width {
  width: unset !important;
}

.chart-container {
  position: relative;
  width: 100%;
}

.borderless {
  border-style: none !important;
}

.category-color-display {
  height: 20px;
  width: 20px;
  float: left;
  margin-right: 5px;
}

mat-expansion-panel {
  &.no-padding-body .mat-expansion-panel-body {
    padding: 0;
  }

  mat-expansion-panel-header.no-padding {
    padding: 0;
  }
}

.svg-icon-primary-color > svg > path {
  stroke: color('primary', 500);
}

.svg-icon-accent-color > svg > path {
  stroke: color('accent', 400);
}

.svg-icon-accent-main-color > svg > path {
  fill: color('accent', 'main');
}

.circle-label {
  @include circle(8px, null, null, null);
  margin-right: 4px;
  display: inline-block;
}

.highlight-feature-overlay {
  max-width: 450px !important;
  min-width: 250px !important;
}

