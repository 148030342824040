@use '../variables' as variables;
@import '../../_tools/mixins';

.mat-mdc-card {

  &.large {
    .mat-mdc-card-content {
      padding: 24px;
    }
  }

  &.normal {
    .mat-mdc-card-content {
      padding: 16px;
    }
  }

  &.small {
    .mat-mdc-card-header {
      padding: 8px;
      border-top-left-radius: variables.$card-dialog-radius !important;
      border-top-right-radius: variables.$card-dialog-radius !important;
    }

    .mat-mdc-card-content {
      padding: 8px;
    }
  }

  &.border {
    border: solid 1px color('primary', 200);
    box-shadow: none !important;
  }

  .mat-mdc-card-actions {
    padding: 8px;
    gap: 8px;
  }

  &.listing-card {
    padding: 0;

    .image-container {
      position: relative;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      height: fit-content;
    }

    .mat-mdc-card-content {
      padding: 16px 16px;
    }

    .title {
      margin-top: 10px;
    }

    .mat-mdc-card-actions {
      padding: 16px;
    }
  }

  &.placeholder {
    border: dashed 1px color('primary', 400);
  }
}
