@use '../variables' as variables;
@import '/src/styles/_tools/mixins';

.mat-mdc-dialog-container {
  .close {
    cursor: pointer;
    float: right;
  }

  .mdc-dialog__content {
    padding: 0 24px 0;
  }

  .mdc-dialog__surface {
    border-radius: variables.$card-dialog-radius !important;
  }
}

.mat-mdc-dialog-actions {
  padding: 8px 24px 20px !important;
}

.small-screen-dialog {
  max-height: 80vh;
  width: 30vw;

  @include set-breakpoint(lt-md) {
    width: 50vw;
  }
  @include set-breakpoint(lt-sm) {
    width: 95vw;
  }
}

.medium-screen-dialog {
  max-height: 80vh;
  width: 50vw;

  @include set-breakpoint(lt-md) {
    width: 70vw;
  }

  @include set-breakpoint(lt-sm) {
    width: 90vw;
  }
}

.medium-large-screen-dialog {
  max-height: 90vh;
  width: 70vw;

  @include set-breakpoint(lt-md) {
    width: 80vw;
  }

  @include set-breakpoint(lt-sm) {
    width: 90vw;
  }
}

.large-screen-dialog {
  height: 95vh;
  width: 99vw;
}

.fullscreen-dialog {
  height: 100vh;
  width: 100vw;
  max-width: 100vw !important;

  .mdc-dialog__surface {
    border-radius: 0 !important;
  }

}

.transparent-screen-dialog {
  max-width: 100vw !important;
  height: 100vh;
  width: 100vw;

  .mat-mdc-dialog-container {
    background: linear-gradient(
        180deg,
        rgba(15, 23, 29, 1) 0%,
        rgba(15, 23, 29, 0) 17.45%),
    rgba(15, 23, 29, 0.75);

    padding: 24px 40px !important;
    border-radius: 0;

    .mdc-dialog__surface {
      background: none;
      box-shadow: none;
    }
  }
}

.mat-mdc-dialog-content {
  max-height: 80vh !important;
}
