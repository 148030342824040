@use 'sass:map';
@use '@angular/material' as mat;
@use 'typography' as typography;
@use 'variables' as variables;
@import '../_tools/mixins';

html, body {
  height: 100%;
}

body {
  @extend .mat-body-2 !optional;
  margin: 0;
  background: color('primary', 50);
}

b {
  @extend .mat-body-strong;
}

a {
  cursor: pointer;
}

h1 {
  @extend .mat-headline-1 !optional;
  margin: variables.$default-heading-margin !important;
}

h2 {
  @extend .mat-headline-2 !optional;
  margin: variables.$default-heading-margin !important;
}

h3 {
  @extend .mat-headline-3 !optional;
  margin: variables.$default-heading-margin !important;
}

h4 {
  @extend .mat-headline-4 !optional;
  margin: variables.$default-heading-margin !important;

  b {
    @include mat.m2-typography-level(typography.$trace-typography, 'headline-4');
    font-weight: typography.$font-weight-bold;
  }
}

h5 {
  @extend .mat-headline-5 !optional;
  margin: variables.$default-heading-margin !important;
}

h6 {
  @extend .mat-headline-6 !optional;
  margin: variables.$default-heading-margin !important;
}

section {
  padding-top: 16px;
  padding-bottom: 16px;
}

// The AIAssistant can use the tag code
code {
  white-space: pre-wrap;  /* Allow wrapping */
  word-break: break-word;  /* Break long words */
}

pre {
  margin-bottom: 15px;
}
