@use '@angular/material' as mat;
@use 'src/styles/base/typography' as typography;
@use 'src/styles/base/variables' as variables;

.ql-editor {
  font-family: typography.$font-family-base, sans-serif !important;
  color: black;

  h1 {
    font-size: mat.m2-font-size(typography.$trace-typography, 'headline-1') !important;
    line-height: mat.m2-line-height(typography.$trace-typography, 'headline-1') !important;
    font-weight: mat.m2-font-weight(typography.$trace-typography, 'headline-1') !important;
    letter-spacing: mat.m2-letter-spacing(typography.$trace-typography, 'headline-1') !important;
    margin: variables.$default-heading-margin !important;
  }

  h2 {
    font-size: mat.m2-font-size(typography.$trace-typography, 'headline-2') !important;
    line-height: mat.m2-line-height(typography.$trace-typography, 'headline-2') !important;
    font-weight: mat.m2-font-weight(typography.$trace-typography, 'headline-2') !important;
    letter-spacing: mat.m2-letter-spacing(typography.$trace-typography, 'headline-2') !important;
    margin: variables.$default-heading-margin !important;
  }

  h3 {
    font-size: mat.m2-font-size(typography.$trace-typography, 'headline-3') !important;
    line-height: mat.m2-line-height(typography.$trace-typography, 'headline-3') !important;
    font-weight: mat.m2-font-weight(typography.$trace-typography, 'headline-3') !important;
    letter-spacing: mat.m2-letter-spacing(typography.$trace-typography, 'headline-3') !important;
    margin: variables.$default-heading-margin !important;
  }

  h4 {
    font-size: mat.m2-font-size(typography.$trace-typography, 'headline-4') !important;
    line-height: mat.m2-line-height(typography.$trace-typography, 'headline-4') !important;
    font-weight: mat.m2-font-weight(typography.$trace-typography, 'headline-4') !important;
    letter-spacing: mat.m2-letter-spacing(typography.$trace-typography, 'headline-4') !important;
    margin: variables.$default-heading-margin !important;
  }

  h5 {
    font-size: mat.m2-font-size(typography.$trace-typography, 'headline-5') !important;
    line-height: mat.m2-line-height(typography.$trace-typography, 'headline-5') !important;
    font-weight: mat.m2-font-weight(typography.$trace-typography, 'headline-5') !important;
    letter-spacing: mat.m2-letter-spacing(typography.$trace-typography, 'headline-5') !important;
    margin: variables.$default-heading-margin !important;
  }

  h6 {
    font-size: mat.m2-font-size(typography.$trace-typography, 'headline-6') !important;
    line-height: mat.m2-line-height(typography.$trace-typography, 'headline-6') !important;
    font-weight: mat.m2-font-weight(typography.$trace-typography, 'headline-6') !important;
    letter-spacing: mat.m2-letter-spacing(typography.$trace-typography, 'headline-6') !important;
    margin: variables.$default-heading-margin !important;
  }

  p {
    font-size: typography.$font-size-paragraph-S !important;
    font-weight: typography.$font-weight-medium !important;
    line-height: typography.$font-lh-paragraph-S !important;
  }
}
