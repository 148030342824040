@use "sass:map";
@import '../../_tools/mixins';

.c-tag {
  background: color(accent, 700);
  color: #FFFFFF;
  border-radius: 999px;
  height: 18px;
  width: fit-content;

  &.medium {
    height: 24px;
  }

  &.large {
    height: 30px;

    .label {
      padding: 5px 12px;
    }
  }

  .icon-left {
    font-size: 12px;
    padding: 4px 0 4px 8px;
    margin-right: -5px;
  }

  .icon-right {
    font-size: 12px;
    padding: 6.5px;
    margin-left: -5px;
  }

  .label {
    font-size: 12px;
    padding: 3px 8px;
  }
}
