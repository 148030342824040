@use 'sass:map';
@import '../_tools/mixins';

:root {
  --glow-animation-0_100-opacity: 0.4;
  --glow-animation-50-opacity: 0.8;
}

@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.trace-rotate3d {
  animation: rotate3d 3s linear infinite;
  transform-style: preserve-3d;
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 5px rgba(color('accent', 500), var(--glow-animation-0_100-opacity));
  }
  50% {
    box-shadow: 0 0 20px rgba(color('accent', 500), var(--glow-animation-50-opacity));
  }
}

.glow-effect {
  animation: glow 1.5s infinite;
  fill: rgba(color('accent', 500), 0.4);
}

.highlight-feature-effect {
  --glow-animation-0_100-opacity: 0.5;
  --glow-animation-50-opacity: 1;
  animation: glow 1s infinite;
}

@keyframes typewriter {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.typewriter-effect {
  width: 0;
  animation: typewriter 1s steps(30) 0s forwards;
}
