@import '../../_tools/mixins';

.c-chip {
  border: 1px solid color('primary', 500);
  color: color('primary', 600);
  padding: 0 10px;
  border-radius: 999px;
  height: 32px;
  width: fit-content;

  .icon-left {
    margin-right: -5px;
  }

  .icon-right {
    margin-left: -5px;
  }

  .label {
    padding: 0 8px;
  }

  &.disabled {
    cursor: default;
    border: 1px solid color('primary', 400);
    color: color('primary', 400) !important;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: color('accent', 300);
    }

    &:active {
      background-color: color('primary', 200);
    }
  }

  &.selected {
    background-color: color("accent", main);
    color: color('accent', 50);
    opacity: 1;
  }

  opacity: 0.8;

  .color {
    @include circle(8px, null, null, null);
    margin-right: 4px;
  }

  &.premium-chip {
    background-color: color('yellow', 100);
    color: color('yellow', 600) !important;
  }
}
