@use "sass:map";
@use "@angular/material" as mat;
@use 'src/styles/base/typography' as typography;
@import "src/styles/_tools/mixins";

.chart-tooltip {
  max-width: 200px;

  .title {
    padding-bottom: 4px;
  }

  .bold {
    font-weight: bold;
  }
}
