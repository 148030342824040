@use "sass:map";
@use "@angular/material" as mat;
@use 'src/styles/base/typography' as typography;
@import "src/styles/_tools/mixins";

.chart-tooltip {
  background: white;
  color: rgba(60, 60, 67, 0.6);
  padding: 10px 8px;
  border-radius: 10px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all .1s ease;
  box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08), 0 8px 16px rgba(50, 50, 71, 0.06);

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  .row {
    display: flex;

    &.single {
      display: block;

      .column {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  .column {
    flex: 50%;

    &:first-child {
      margin-right: 30px;
    }
  }

  .desc {
    white-space: nowrap;
    margin-top: 4px;
    margin-bottom: 4px;
    font: {
      size: typography.$font-size-text-S;
      weight: typography.$font-weight-regular;
    }
    line-height: typography.$font-lh-text;
  }

  .value {
    margin-bottom: 4px;
    color: color('primary', 600);
    font: {
      size: typography.$font-size-text-L;
      weight: typography.$font-weight-bold;
    }
    line-height: typography.$font-lh-text;
  }

  .range {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 4px;
  }
}

.chart-custom-label {
  height: 16px;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color('yellow', 200);
  border-radius: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: color('primary', 600);
  font: {
    size: typography.$font-size-text-S;
    weight: typography.$font-weight-regular;
  }
  line-height: typography.$font-lh-text;

  position: absolute;
}

.chart-legend {
  margin-top: 30px;
  margin-bottom: 30px;

  .category {
    height: 24px;
    width: fit-content;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 8px;
    border: 1px solid color('primary', 100);
    margin-bottom: 4px;

    opacity: 0.9;
    color: color('primary', 500);
    font: {
      size: typography.$font-size-paragraph-S;
      weight: typography.$font-weight-light;
    }
    line-height: typography.$font-lh-paragraph-XS;

    .color {
      @include circle(8px, null, null, null);
      margin-right: 4px;
      display: inline-block;
    }
  }
}

.tooltip-icon {
  height: 18px !important;
  width: 18px !important;
  border-radius: 50% !important;
  margin-top: 1.5px;
  margin-bottom: 8px;
  margin-right: 6px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}

.filled-tooltip-icon {
  @include circle(20px, null, null, null);
  margin: 0 4px 8px 0;

  .category-icon {
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 14px;
    height: 14px;
    display: inherit;
    margin: 3px auto;
  }
}

.office {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/office.svg');
}

.hybrid {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/hybrid.svg');
}

.remote {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/remote.svg');
}

.ground {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/car.svg');
}

.household {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/house.svg');
}

.food {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/trash.svg');
}

.air {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/takeoff.svg');
}

.goods {
  background-image: url('../../../../../../../src/assets/portal/svgs/icons/package.svg');
}
