@use "sass:map";
@import '../../_tools/mixins';

.mat-mdc-tab-group {
  .mat-mdc-tab-header {

    .mdc-tab-indicator__content {
      &--underline {
        border-top-width: 4px;
        border-color: color(accent, 500) !important;
      }
    }

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab {
          &.warning {
            .mdc-tab__content {
              .mdc-tab__text-label {
                color: red !important;
              }
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    padding-top: 24px;
  }

  &.block-tab {
    .mat-mdc-tab-header {
      .mdc-tab-indicator__content {
        &--underline {
          border-top: none;
        }
      }

      .mat-mdc-tab-labels {
        .mdc-tab {
          height: 72px;
          margin-right: 24px;
          background: color(primary, 100);
          box-shadow: 0 4px 16px rgba(15, 23, 29, 0.04);
          border-radius: 16px 16px 0 0;

          &--active {
            background: #fff;
          }

          .mat-icon {
            font-size: 24px;
            margin-right: 10px;

            &::before {
              margin-right: 0;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .mdc-tab__ripple {
        &::before {
          border-radius: 16px 16px 0 0;
        }
      }
    }
  }
}
