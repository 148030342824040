@import '../../_tools/mixins';

.mat-mdc-form-field {
  border-style: none;

  &.round {
    border: 1px solid color(accent, 500);
  }

  &.inline-form {
    border-bottom: 1px solid color(accent, 500);

    .mat-mdc-form-field-infix {
      display: flex;
      padding: 0 !important;
      min-height: auto;
      width: fit-content;
    }

    .mdc-notched-outline__leading {
      border: none !important;
    }

    .mdc-notched-outline__trailing {
      border: none !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-select-arrow-wrapper {
      display: none;
    }
  }
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper,
.mat-mdc-form-field .mdc-floating-label {
  will-change: auto;
}

// Tailwind issue https://tailwindcss.com/docs/preflight#border-styles-are-reset-globally
.mat-mdc-form-field * {
  border-style: none;
}
