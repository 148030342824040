.mat-mdc-slide-toggle {
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-width: 40px;
  --mdc-switch-track-shape: 12px;
  --mdc-switch-handle-height: 18px;
  --mdc-switch-handle-width: 18px;

  .mdc-switch__handle {
    left: 3px
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch--selected {
    .mdc-switch__handle {
      left: -3px
    }
  }
}
