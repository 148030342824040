@import '../../_tools/mixins';

.mat-mdc-tooltip {
  $background: color(primary, darker);

  &.mdc-tooltip {
    overflow: visible !important;
    position: relative;
    border-left: 6px solid $background;
    margin-left: 8px;
    border-radius: 6px;

    .mdc-tooltip__surface {
      border-radius: 0 6px 6px 0;
      background: $background;
      padding: 8px 12px 8px 6px;
    }

    &.pointed-tooltip {
      &::before {
        position: absolute;
        content: '';
        display: inline-block;
        background-color: $background;
        clip-path: polygon(50% 0, 0 50%, 50% 100%);
        left: -12px !important;
        width: 15px;
        height: 15px;
        top: 50% !important;
        transform: translateY(-50%);
        border-radius: 6px;
      }
    }

  }
}
