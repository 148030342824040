@use 'sass:map';
@use '@angular/material' as mat;
@use '../base/colour-palettes' as palettes;
@import "/src/styles/_settings/global-settings";

@mixin set-breakpoint($breakpoint) {
  @if map-has-key($global-breakpoints, $breakpoint) {
    @media #{map-get($global-breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($global-breakpoints)}.";
  }
}

@mixin circle($size, $background, $color, $border:none) {
  height: $size !important;
  width: $size !important;
  display: inline-block;
  background: $background;
  color: $color;
  border: $border;
  border-radius: 50%;
}

@function color($palette, $hue, $contrast:false) {
  $actual_palette: palettes.$portal-primary;
  @if $palette == 'primary' {
    $actual_palette: palettes.$portal-primary;
  } @else if $palette == 'accent' {
    $actual_palette: palettes.$portal-accent;
  } @else if $palette == 'warn' {
    $actual_palette: palettes.$portal-warn;
  } @else if $palette == 'blue' {
    $actual_palette: palettes.$portal-blue;
  } @else if $palette == 'yellow' {
    $actual_palette: palettes.$portal-yellow;
  } @else if $palette == 'purple' {
    $actual_palette: palettes.$portal-purple;
  } @else {
    @error "Unknown palette #{$palette}.";
  }
  @if $contrast == true {
    @return map.get(map.get($actual_palette, contrast), $hue);
  } @else {
    @return map.get($actual_palette, $hue);
  }
}

@mixin text-hover($family, $weight) {
  cursor: pointer;
  font: {
    family: $family;
    weight: $weight;
  }
}
