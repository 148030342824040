@use '@angular/material' as mat;

// paragraph design vars
$font-size-paragraph-XS: 12px;
$font-size-paragraph-S: 14px;
$font-size-paragraph-M: 16px;
$font-size-paragraph-L: 18px;

$font-lh-paragraph-XS: 16px;
$font-lh-paragraph-S: 20px;
$font-lh-paragraph-M: 24px;
$font-lh-paragraph-L: 28px;

// text design vars
$font-size-text-XS: 12px;
$font-size-text-S: 14px;
$font-size-text-L: 18px;

$font-lh-text: 1;
$font-lh-text-S: 21px;

// Define font families
$font-family-base: 'Montserrat';
$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

$h1-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 36px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 56px,
  $letter-spacing: normal
);

$h2-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 30px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 48px,
  $letter-spacing: normal
);

$h3-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 26px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 40px,
  $letter-spacing: normal
);

$h4-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 20px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 32px,
  $letter-spacing: normal
);

$h5-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 16px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 28px,
  $letter-spacing: normal
);

$h6-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 16px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 28px,
  $letter-spacing: normal
);

$subtitle-1-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 16px,
  $font-weight: $font-weight-semi-bold,
  $line-height: 28px,
  $letter-spacing: normal
);

$body-1-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 14px,
  $font-weight: $font-weight-medium,
  $line-height: 24px,
  $letter-spacing: normal
);

// About h5 and h6 from the docs
// The .mat-h5 and .mat-h6 styles don't directly correspond to a specific Material Design typography level.
// The .mat-h5 style uses the body-2 level with the font-size scaled down by 0.83.
// The .mat-h6 style uses the body-2 level with the font-size scaled down by 0.67.

$body-2-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 14px,
  $font-weight: $font-weight-medium,
  $line-height: 24px,
  $letter-spacing: normal
);

$body-2-strong-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 14px,
  $font-weight: $font-weight-bold,
  $line-height: 24px,
  $letter-spacing: normal
);

$caption-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 12px,
  $font-weight: $font-weight-medium,
  $line-height: 20px,
  $letter-spacing: normal
);

$button-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 14px,
  $font-weight: $font-weight-bold,
  $line-height: 24px,
  $letter-spacing: normal
);

$overline-level: mat.m2-define-typography-level(
  $font-family: $font-family-base,
  $font-size: 12px,
  $font-weight: $font-weight-medium,
  $line-height: 20px,
  $letter-spacing: normal
);

$trace-typography: mat.m2-define-typography-config(
  $font-family: $font-family-base,
  $headline-1: $h1-level,
  $headline-2: $h2-level,
  $headline-3: $h3-level,
  $headline-4: $h4-level,
  $headline-5: $h5-level,
  $headline-6: $h6-level,
  $subtitle-1: $subtitle-1-level,
  $body-1: $body-1-level,
  $body-2: $body-2-level,
  $subtitle-2: $body-2-strong-level,
  $caption: $caption-level,
  $button: $button-level,
  $overline: $overline-level
);
