@use "sass:map";
@import '../../_tools/mixins';

.c-badge {
  background: color(accent, 700);
  color: #FFFFFF;
  border-radius: 100px;
  height: 20px;
  width: fit-content;
  padding: 8px;
  font-size: 12px;

  &.big {
    height: 25px;
    font-size: 14px;
  }
}
