.mat-icon {
  font-size: 20px;
  width: unset !important;
  height: unset !important;

  &.small {
    font-size: 16px;
  }

  &.medium {
    font-size: 24px;
  }

  &.large {
    font-size: 32px;
  }

  &.extra-large {
    font-size: 64px;
  }
}
