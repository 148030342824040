@use "sass:map";
@import '../../_tools/mixins';

.mat-mdc-radio-group {
  .radio-button {
    border: 1px solid color(primary, 400);
    background-color: color(primary, lighter);
    height: 56px;
    border-radius: 16px;
    padding: 16px 32px;

    &:hover {
      background-color: color(primary, 100);
    }

    &:focus {
      background-color: color(primary, 200);
    }

    &.mat-mdc-radio-checked {
      border: 2px solid color(accent, darker);
      background-color: color(accent, 100);

      &:hover {
        background-color: color(accent, 200);
      }

      &:focus {
        background-color: color(accent, 300);
      }
    }
  }

  .radio-box {
    border: 1px solid color(primary, 400);
    border-radius: 8px;
    width: 224px;
    padding: 26px 16px 20px 16px;

    .mdc-form-field {
      flex-direction: column;
      white-space: normal;
      text-align: center;

      .label {
        margin: 10px 0 8px 0;
        line-height: normal;
      }

      .desc {
        margin-top: 4px;
        padding: 0 22px;
        line-height: normal;
      }
    }

    &:hover {
      background-color: color(primary, 100);
    }

    &:focus {
      background-color: color(primary, 200);
    }

    &.mat-mdc-radio-checked {
      border: 2px solid color(accent, darker);
      background-color: color(accent, 100);

      &:hover {
        background-color: color(accent, 200);
      }

      &:focus {
        background-color: color(accent, 300);
      }
    }
  }
}
