@font-face {
  font-family: 'trace';
  src: url('../font/trace.eot?25749362');
  src: url('../font/trace.eot?25749362#iefix') format('embedded-opentype'),
       url('../font/trace.woff2?25749362') format('woff2'),
       url('../font/trace.woff?25749362') format('woff'),
       url('../font/trace.ttf?25749362') format('truetype'),
       url('../font/trace.svg?25749362#trace') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'trace';
    src: url('../font/trace.svg?25749362#trace') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "trace";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-info-circled:before { content: '\e741'; } /* '' */
.icon-logout:before { content: '\e800'; } /* '' */
.icon-eye:before { content: '\e801'; } /* '' */
.icon-delete:before { content: '\e802'; } /* '' */
.icon-cancel-circled:before { content: '\e803'; } /* '' */
.icon-edit:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-tags:before { content: '\e806'; } /* '' */
.icon-ship:before { content: '\e807'; } /* '' */
.icon-user-plus:before { content: '\e808'; } /* '' */
.icon-plug:before { content: '\e809'; } /* '' */
.icon-arrows-ccw:before { content: '\e80a'; } /* '' */
.icon-user-2:before { content: '\e80b'; } /* '' */
.icon-warning-empty:before { content: '\e80c'; } /* '' */
.icon-chat:before { content: '\e80d'; } /* '' */
.icon-fuel:before { content: '\e819'; } /* '' */
.icon-disconnect:before { content: '\e924'; } /* '' */
.icon-bus-1:before { content: '\ea14'; } /* '' */
.icon-car:before { content: '\ea15'; } /* '' */
.icon-bike:before { content: '\ea16'; } /* '' */
.icon-laptop:before { content: '\ea17'; } /* '' */
.icon-move:before { content: '\f047'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-building:before { content: '\f0f7'; } /* '' */
.icon-alert-circle:before { content: '\f102'; } /* '' */
.icon-alert-octagon:before { content: '\f103'; } /* '' */
.icon-aperture:before { content: '\f10a'; } /* '' */
.icon-archive:before { content: '\f10b'; } /* '' */
.icon-arrow-down:before { content: '\f10c'; } /* '' */
.icon-arrow-left:before { content: '\f110'; } /* '' */
.icon-arrow-right:before { content: '\f112'; } /* '' */
.icon-arrow-up:before { content: '\f114'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-award:before { content: '\f119'; } /* '' */
.icon-bar-chart:before { content: '\f11b'; } /* '' */
.icon-bell:before { content: '\f11e'; } /* '' */
.icon-book:before { content: '\f122'; } /* '' */
.icon-bookmark:before { content: '\f124'; } /* '' */
.icon-briefcase:before { content: '\f126'; } /* '' */
.icon-check:before { content: '\f12b'; } /* '' */
.icon-check-circle:before { content: '\f12c'; } /* '' */
.icon-chevron-down:before { content: '\f12e'; } /* '' */
.icon-chevron-left:before { content: '\f12f'; } /* '' */
.icon-chevron-right:before { content: '\f130'; } /* '' */
.icon-chevron-up:before { content: '\f131'; } /* '' */
.icon-chevrons-down:before { content: '\f132'; } /* '' */
.icon-clock:before { content: '\f139'; } /* '' */
.icon-cloud:before { content: '\f13a'; } /* '' */
.icon-cloud-lightning:before { content: '\f13c'; } /* '' */
.icon-copy:before { content: '\f147'; } /* '' */
.icon-cpu:before { content: '\f150'; } /* '' */
.icon-crosshair:before { content: '\f153'; } /* '' */
.icon-database:before { content: '\f154'; } /* '' */
.icon-dollar-sign:before { content: '\f15a'; } /* '' */
.icon-download:before { content: '\f15b'; } /* '' */
.icon-droplet:before { content: '\f15e'; } /* '' */
.icon-edit-3:before { content: '\f161'; } /* '' */
.icon-external-link:before { content: '\f162'; } /* '' */
.icon-eye-1:before { content: '\f163'; } /* '' */
.icon-eye-off:before { content: '\f164'; } /* '' */
.icon-file:before { content: '\f169'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-file-plus:before { content: '\f16b'; } /* '' */
.icon-file-text:before { content: '\f16c'; } /* '' */
.icon-git-pull-request:before { content: '\f179'; } /* '' */
.icon-heart:before { content: '\f181'; } /* '' */
.icon-help-circle:before { content: '\f182'; } /* '' */
.icon-home:before { content: '\f184'; } /* '' */
.icon-info:before { content: '\f187'; } /* '' */
.icon-link:before { content: '\f18e'; } /* '' */
.icon-list:before { content: '\f191'; } /* '' */
.icon-loader:before { content: '\f192'; } /* '' */
.icon-lock:before { content: '\f193'; } /* '' */
.icon-log-out:before { content: '\f195'; } /* '' */
.icon-mail:before { content: '\f196'; } /* '' */
.icon-menu:before { content: '\f19c'; } /* '' */
.icon-more-vertical:before { content: '\f1a9'; } /* '' */
.icon-package:before { content: '\f1b0'; } /* '' */
.icon-paperclip:before { content: '\f1b1'; } /* '' */
.icon-percent:before { content: '\f1b5'; } /* '' */
.icon-pie-chart:before { content: '\f1bd'; } /* '' */
.icon-play:before { content: '\f1be'; } /* '' */
.icon-play-circle:before { content: '\f1bf'; } /* '' */
.icon-plus:before { content: '\f1c0'; } /* '' */
.icon-save:before { content: '\f1ce'; } /* '' */
.icon-search:before { content: '\f1d0'; } /* '' */
.icon-send:before { content: '\f1d1'; } /* '' */
.icon-settings:before { content: '\f1d3'; } /* '' */
.icon-shield:before { content: '\f1d6'; } /* '' */
.icon-shopping-cart:before { content: '\f1d9'; } /* '' */
.icon-slash:before { content: '\f1df'; } /* '' */
.icon-star:before { content: '\f1e5'; } /* '' */
.icon-sun:before { content: '\f1e7'; } /* '' */
.icon-thumbs-up:before { content: '\f1f0'; } /* '' */
.icon-trash:before { content: '\f1f4'; } /* '' */
.icon-trending-down:before { content: '\f1f7'; } /* '' */
.icon-trending-up:before { content: '\f1f8'; } /* '' */
.icon-truck:before { content: '\f1fa'; } /* '' */
.icon-upload:before { content: '\f202'; } /* '' */
.icon-user-check:before { content: '\f205'; } /* '' */
.icon-user-minus:before { content: '\f206'; } /* '' */
.icon-bus:before { content: '\f207'; } /* '' */
.icon-users:before { content: '\f209'; } /* '' */
.icon-volume:before { content: '\f20d'; } /* '' */
.icon-cancel:before { content: '\f215'; } /* '' */
.icon-zap:before { content: '\f21a'; } /* '' */
.icon-industry:before { content: '\f275'; } /* '' */
.icon-forms:before { content: '\f298'; } /* '' */
.icon-user:before { content: '\f2be'; } /* '' */
