@import '../_tools/mixins';

.mat-primary {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: default;
  }
}

.mat-accent {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: default;
  }
}

.mat-warn {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-color: default;
  }
}

.mat-button-toggle-group {
  --mat-standard-button-toggle-shape: 6px;

  .mat-button-toggle {
    --mat-standard-button-toggle-background-color: #{color(primary, lighter)};

    &.mat-button-toggle-checked {
      --mat-standard-button-toggle-selected-state-background-color: #{color('accent', 'main')};
      --mat-standard-button-toggle-selected-state-text-color: #{color('accent', lighter)};
    }
  }
}

:root {
  --mdc-outlined-button-container-shape: 6px;
  --mdc-filled-button-container-shape: 6px;
  --mdc-protected-button-container-shape: 6px;
  --mdc-extended-fab-container-shape: 10px;
  --mdc-outlined-card-container-shape: 8px;
  --mat-fab-disabled-state-container-color: #{color(primary, 300)};

  // To sync with custom rules in tailwind
  --mdc-tailwind-card-border-radius: 6px;
}

//--mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
//--mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
